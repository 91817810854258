import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react';
import "./style.css"

import logo from "../../images/logo-mobile.png"
import imglogout from "../../images/logout.png"
import menuclose from "../../images/menu-close.png"
import menusearch from "../../images/menu-search.png"



const NavBar = ({type, items, activePath, fullPath, closeHandler, loggedin, suffix, fadeOption, defaultSearch, hasMenu})=> {
	//const parentURL = activePath===""?fullPath.split("/")[0]:activePath;
	const [activeURL, setActiveURL] = useState("");
	const [searchkey, setSearchkey] = useState(defaultSearch);

	const menudropdowncharacter = <>&nbsp;&#9662;</>

	const searchStart = (e, key) => {
		if (e) {
			e.preventDefault();
		}

	}

	const handleSearchChange = (e) => {
		setSearchkey(e.target.value.toLowerCase());
		searchStart(null, e.target.value);
	};

	return <>
			<ul className={"navbar-nav"+suffix+" "+type + suffix + "-nav navbar-menu-"+fadeOption}>
			{hasMenu && <>
				<li className={"navbar-close"+suffix+" "+type + suffix + "-close"}>
					<img src={logo} alt="Menu" className={"navbar-close-logo " +type + suffix + "-close-logo"}/>
					<button  title={"Close"} className={"navbar-close " +type + suffix + "-close"} onClick={closeHandler}><img src={menuclose} alt="Close" /></button>
				</li>
				<li className={"navbar-search"+suffix+" "+type + suffix + "-search"}>
					<input className={"navbar-search"+suffix+" "+type + suffix + "-search"} type={"text"} value={searchkey} onChange={handleSearchChange} onBlur={handleSearchChange} placeholder={"Menu Search"} />
					{searchkey !== "" &&
						<button className={"navbar-search " +type + suffix + "-search navbar-searchclear " +type + suffix + "-searchclear"} onClick={(e)=>{e.preventDefault(); setSearchkey("");}}><img src={menuclose} alt="X" /></button>
					}
					<button title={"Search"} className={"navbar-search " +type + suffix + "-search"} onClick={(e)=>{searchStart(e, searchkey);}}><img src={menusearch} alt="Search" /></button>
				</li>
			</>}
			{items.map((entry, navbaridx) => {
				var hasSubitems = false;
				var isParentUrl = false;
				if (entry.hasOwnProperty("url")) {
					if (entry.url === fullPath) {
						isParentUrl = true;
					}
				}
				if (entry.hasOwnProperty("subitems")) {
					hasSubitems=true;
				}
				if (isParentUrl===false && hasSubitems) {
					if (JSON.stringify(entry.subitems).indexOf('"url":"'+fullPath+'"')>=0) {
						isParentUrl = true;
					}
				}
				return <li
					onMouseEnter={(e)=>{if (entry.subitems) {e.preventDefault(); setActiveURL(entry.url);}}}
					onMouseLeave={(e)=>{if (entry.subitems) {e.preventDefault(); setActiveURL("");}}}
					key={"navbar_"+suffix+"_"+type+"_"+navbaridx}
					className={(isParentUrl?"active ":"") + "navbar-nav"+suffix+" "+type + suffix + "-nav"}
				>
					{hasMenu === false ?
						<>
							<Link
								to={"/"+entry.url}
								className={"font-style-bold navbar-menu"+suffix+" navbar-nav"+suffix+" "+type + suffix + "-nav"+(fullPath === entry.url?" active":"")}
								activeClassName="active"
							>
								<div className={"navbar-nav-aligner"}></div>
									<span className={"navbar-nav-aligner"}>{entry.title}{entry.subitems && menudropdowncharacter}</span>
								<div className={"navbar-nav-aligner"}></div>
							</Link>
							{ ((activeURL===entry.url) && entry.subitems) &&
								<NavBar activePath={activePath} fullPath={fullPath} items={entry.subitems} type={type} suffix={suffix + "-sub"} />
							}
						</>
					:
						<>
							{(searchkey === "" || (entry.title.toLowerCase().indexOf(searchkey)>=0 && !entry.subitems) || (entry.subitems &&JSON.stringify(entry.subitems).toLowerCase().indexOf(searchkey)>=0)) && <>
								<button
									title={entry.title}
									onClick={(e) => { if (entry.subitems) {e.preventDefault(); setActiveURL(entry.url);} else { navigate("/"+entry.url); } }}
									className={"font-style-bold navbar-menu"+suffix+" navbar-button"+suffix+" "+type + suffix + "-button"}
								>
									{entry.title}{entry.subitems && menudropdowncharacter}
								</button>
								{(entry.subitems && (activeURL===entry.url || isParentUrl)) &&
									<NavBar defaultSearch={searchkey} activePath={activePath} fullPath={fullPath} items={entry.subitems} type={type} suffix={suffix + "-sub"} />
								}
							</>}
						</>
					}
				</li>
			})}
			{loggedin === true && hasMenu && (
				<li
					className={
						"navbar-nav" +
						suffix +
						" " +
						type +
						suffix +
						"-nav"+
						" navbar-logoutbutton" +
						suffix +
						" " +
						type +
						suffix +
						"-logoutbutton"
					}
				>
					<button
						title={"Logout"}
						onClick={() => navigate("/logout")}
						className={
							"font-style-bold navbar-menu" +
							suffix +
							" navbar-button" +
							suffix +
							" " +
							type +
							suffix +
							"-button"
						}
					>
						<img src={imglogout} alt="Logout" className={
							"navbar-logoutbutton" +
							suffix +
							" " +
							type +
							suffix +
							"-logoutbutton"} />
						{"Logout"}
					</button>
				</li>
			)}
		</ul>
	</>
}

NavBar.propTypes = {
	loggedin: PropTypes.bool,
	suffix: PropTypes.string,
	fadeOption: PropTypes.string,
	hasMenu: PropTypes.bool,
	defaultSearch: PropTypes.string
};

NavBar.defaultProps = {
	loggedin: false,
	hasMenu: false,
	fadeOption: `none`,
	suffix: ``,
	defaultSearch: ``
};


export default NavBar
