import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import NavBarMenu from "../navbar/navbarmenu"
import "./style.css"

import logo from "../../images/logo.png"
import logomobile from "../../images/logo-mobile.png"

const HEADERMENU_MAXCOUNT=5;

const Header = ({ displayType, activePath, fullPath, pageTitle, placeHolder, initDisplayType, authenticated, mobileTitle, menulist, customClass }) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)
	return (
	<header className={placeHolder?"header-placeholder":"header-freeze"}>
		<div className="header-background">
			{(displayType>= 2 || menulist.length > HEADERMENU_MAXCOUNT)?
				<>
					<Link to="/" className={"header-logo header-logomobile"}>
						<img src={logomobile} alt={data.site.siteMetadata.title} />
					</Link>
					<span className="header-mobiletitle font-size-medium">{(mobileTitle && initDisplayType===false)?pageTitle:""}</span>
				</>
			:
				<Link to="/" className={"header-logo"}>
					<img src={logo} alt={data.site.siteMetadata.title} />
				</Link>
			}
			{ (initDisplayType===false && (menulist.length > 0 || authenticated)) && <>
				<NavBarMenu loggedin={authenticated} displayType={menulist.length > HEADERMENU_MAXCOUNT?3: displayType} items={menulist} type="header" activePath={placeHolder?"":activePath} fullPath={placeHolder?"":fullPath} />
			</>}
		</div>
	</header>
	)
}

Header.propTypes = {
	menulist: PropTypes.array,
	customClass: PropTypes.string,
	displayType: PropTypes.number,
	activePath: PropTypes.string,
	fullPath: PropTypes.string,
	pageTitle: PropTypes.string,
	placeHolder: PropTypes.bool,
	authenticated: PropTypes.bool,
	mobileTitle: PropTypes.bool,
	initDisplayType: PropTypes.bool
}

Header.defaultProps = {
	menulist: [],
	customClass: ``,
	displayType: 3,
	placeHolder: false,
	activePath: ``,
	fullPath: ``,
	pageTitle: ``,
	authenticated: false,
	mobileTitle: false,
	initDisplayType: false
}

export default Header
