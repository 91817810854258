import { navigate } from "gatsby"
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types"
import "./style.css"

import menu from "../../images/menu.png"
import logout from "../../images/logout.png"

import NavBar from "./navbar"


const NavBarMenu = ({type, displayType, items, activePath, fullPath, loggedin})=> {
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		if (typeof window === 'undefined') return;
		function handleResizeEffect() {
			setShowMenu(false);
		}
		window.addEventListener('resize', handleResizeEffect)
		return _ => {
			window.removeEventListener('resize', handleResizeEffect)
		}
	})
	const handleClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		setShowMenu(!showMenu);
	};
	const navclass=displayType<2 ?"nav":"menu"
	return <>
				<nav className={"navbar-"+navclass+" "+type + "-"+navclass} >
					{showMenu && <div role="button" tabIndex='0'  className="nav-shadow" onClick={handleClick} onKeyDown={handleClick}>&nbsp;</div>}
					{ navclass === "nav" ?
						<NavBar items={items} type={type} activePath={activePath} fullPath={fullPath} />
					:
						<>
							<NavBar
								loggedin={loggedin}
								items={items}
								closeHandler={handleClick}
								type={type}
								activePath={activePath}
								fullPath={fullPath}
								hasMenu={true}
								fadeOption={showMenu?"enter":"leave"} />
							{items.length > 0 && <>
								{(items.length > 1 || items[0].hasOwnProperty("subitems")) &&
									<button  title={"Menu"} className={"navbar-menu "+type + "-menu"} onClick={handleClick}>
										<img src={menu} alt="Menu" />
									</button>
								}
							</>}
						</>
					}
				</nav>
				{loggedin && navclass === "nav" && (
					<button
						title={"Logout"}
						onClick={() => {
							navigate("/logout");
						}}
						className="navbar-logout header-logout"
					>
						<img src={logout} alt={"Logout"} />
					</button>
				)}
			</>
}


NavBarMenu.propTypes = {
	loggedin: PropTypes.bool,
	displayType: PropTypes.number,
	activePath: PropTypes.string,
	fullPath: PropTypes.string,
	type: PropTypes.string,
	items: PropTypes.array
};

NavBarMenu.defaultProps = {
	loggedin: false,
	displayType: 3,
	activePath: ``,
	fullPath: ``,
	items: [],
	type: `header`
};

export default NavBarMenu
