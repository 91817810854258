import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import NavBar from "../navbar/navbar"

import menulist from "../../content/footermenulist.json"



const Footer = ({ displayType, activePath }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
          siteUrl
        }
      }
    }
  `)
  return (
  	<footer>
      {menulist.length > 0 &&
        <NavBar items={menulist} type="footer" activePath={activePath} />
      }
      <div className="footer-info">
        <span className="font-size-tiny font-style-bold">Copyright {new Date().getFullYear()} {data.site.siteMetadata.author} All Rights Reserved.</span>
      </div>

  	</footer>
  )

}
Footer.propTypes = {
  displayType: PropTypes.number,
  activePath: PropTypes.string
}

Footer.defaultProps = {
  displayType: 3,
  activePath: ``
}

export default Footer
